/* eslint-disable no-undef */
import {
  NotFoundError,
  ServerError,
  configErrorCatchHandler,
  // configErrorPageHandler,
  CUSTOM_ERROR_STATUS_CODE,
  ERROR_PAGE_STATUS_CODE,
} from 'daco-common';
/**
 *
 * @param {*} _error customError
 */
export const errorCatchHandlerCommon = (error) => {
  const errorHandlerFn = configErrorCatchHandler([
    {
      customErrorType: NotFoundError,
      handlerError: () => showError({ statusCode: 404 }),
    },
    {
      customErrorType: ServerError,
      handlerError: () => showError({ statusCode: 500 }),
    },
  ]);
  errorHandlerFn(error);
};

// ssr 시에는 crateError 만 사용해도 error 가 발생하면 해당 error page 로 이동은 자동으로 된다.
// 하지만 csr 시에는 error page 로 이동을 위해서는 showError 를 사용해야 한다.
// ssr 시에도 에러발생시 catch 에서 의도적으로 showError 를 사용할 수 있다.
// 정리 필요.
export const errorPageHandlerCommon = (customErrorStatusCode) => {
  try {
    if (customErrorStatusCode === CUSTOM_ERROR_STATUS_CODE.NOT_FOUND) {
      // showError({ statusCode: ERROR_PAGE_STATUS_CODE.NOT_FOUND });
      throw createError({
        statusCode: ERROR_PAGE_STATUS_CODE.NOT_FOUND,
        statusMessage: 'Page Not Found',
      });
    }
    // showError({ statusCode: ERROR_PAGE_STATUS_CODE.TEMPERARY_ERROR });
    throw createError({
      statusCode: ERROR_PAGE_STATUS_CODE.TEMPERARY_ERROR,
      statusMessage: 'Temporary Error',
    });
  } catch (error) {
    console.error('error', error);
    showError({ statusCode: error.statusCode });
  }

  // const handlerFn = configErrorPageHandler([
  //   {
  //     statusCode: CUSTOM_ERROR_STATUS_CODE.NOT_FOUND,
  //     handlerError: () => {
  //       throw createError({
  //         statusCode: ERROR_PAGE_STATUS_CODE.NOT_FOUND,
  //         statusMessage: 'Page Not Found',
  //       });
  //     },
  //   },
  //   {
  //     statusCode: CUSTOM_ERROR_STATUS_CODE.SERVER_ERROR,
  //     handlerError: () => {
  //       throw createError({
  //         statusCode: ERROR_PAGE_STATUS_CODE.SERVER_ERROR,
  //         statusMessage: 'Page Not Found',
  //       });
  //     },
  //   },
  //   {
  //     statusCode: CUSTOM_ERROR_STATUS_CODE.DATA_PARSE_ERROR,
  //     handlerError: () => {
  //       throw createError({
  //         statusCode: ERROR_PAGE_STATUS_CODE.SERVER_ERROR,
  //         statusMessage: 'Page Not Found',
  //       });
  //     },
  //   },
  // ]);
  // console.log('customErrorStatusCode', customErrorStatusCode);
  // handlerFn(customErrorStatusCode);
};
